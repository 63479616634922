import Img from "gatsby-image";
import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  min-width: 158px;
  max-width: 255px;
`;

export const StyledImage = styled(Img)`
  border: 2px solid transparent;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 2px solid #e43f5a;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    transform: scale(1.01);
  }
`;

export const Category = styled.div`
  display: inline-block;
  margin-top: 30px;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b50000;
  text-transform: uppercase;
`;

export const Excerpt = styled.a`
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
  margin-top: 7px;
  width: 100%;
  border-bottom: 2px solid transparent;
  text-decoration: none;

  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid #e43f5a;
  }
`;
