import styled from "styled-components";

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 40px;
  justify-items: center;

  @media (max-width: 768px) {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
  }
`;
