import {
  CenteredContent,
  Container,
  Paragraph,
  Title,
} from "./feed-component-styles";
import React, { useEffect, useState } from "react";
import Button from "@components/button";
import EmptySpace from "@components/empty-space/component";
import FeedCardGrid from "./feed-card-grid-component";
import { useFeedCollection } from "@queries/use-feed";

const NUMBER_OF_FEED_TO_LOAD = 4;

const FeedComponent = () => {
  const { getAllFeedFrontmatter } = useFeedCollection();
  const allFeed = getAllFeedFrontmatter();

  const [list, setList] = useState([
    ...allFeed.slice(0, NUMBER_OF_FEED_TO_LOAD),
  ]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(
    allFeed.length > NUMBER_OF_FEED_TO_LOAD
  );

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allFeed.length;
      const results = isMore
        ? allFeed.slice(currentLength, currentLength + NUMBER_OF_FEED_TO_LOAD)
        : [];
      setList([...list, ...results]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  useEffect(() => {
    const isMore = list.length < allFeed.length;
    setHasMore(isMore);
  }, [list]);

  return (
    <Container>
      <Title>Novidades</Title>
      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </Paragraph>

      <EmptySpace desktop={{ margin: 100 }} mobile={{ margin: 90 }} />

      <FeedCardGrid list={list} />

      <EmptySpace desktop={{ margin: 90 }} mobile={{ margin: 80 }} />

      <CenteredContent>
        {hasMore && (
          <Button
            onClick={handleLoadMore}
            size="large"
            color="white"
            bg="orange"
            border="none"
          >
            carregar mais
          </Button>
        )}
      </CenteredContent>
    </Container>
  );
};

export default FeedComponent;
