import EmptySpace from "@components/empty-space/component";
import FeedCollection from "@components/feed/feed-component";
import FullBleed from "@components/full-bleed";
import Layout from "@components/layout";
import Newsletter from "@components/newsletter/newsletter-component";
import React from "react";
import Seo from "@components/seo";
import { useImage } from "@queries/use-image";

export default function Novidades() {
  const { getFluidImageNameAndExtension } = useImage();
  const seoImage = getFluidImageNameAndExtension(
    "executive-working-laptops",
    "jpg"
  );

  return (
    <Layout isHeroAvailable={false}>
      <EmptySpace desktop={{ margin: 160 }} mobile={{ margin: 60 }} />

      <FeedCollection />

      <EmptySpace desktop={{ margin: 160 }} mobile={{ margin: 60 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>
      <Seo
        title="Todas as novidades do mundo TreeTree2"
        description="Acompanha todas as novidades do TreeTree2 e de outras atividades que contribuem para o desenvolvimento das crianças e jovens com gosto pela Ciência."
        image={seoImage}
      />
    </Layout>
  );
}
