import { graphql, useStaticQuery } from "gatsby";

export const useFeedCollection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { fields: { folder: { eq: "novidades" } } }) {
          edges {
            node {
              id
              slug
              frontmatter {
                title
                category
                summary
                thumbnailImage
                thumbnailImageAltText
                highlighted
              }
            }
          }
        }
      }
    `
  );

  const getAllFeedFrontmatter = () => {
    return data?.allMdx?.edges.map((edge) => {
      const { frontmatter, id, slug } = edge?.node;

      return {
        id: id,
        slug: slug,
        title: frontmatter.title,
        category: frontmatter.category,
        summary: frontmatter.summary,
        thumbnailImage: frontmatter.thumbnailImage,
        thumbnailImageAltText: frontmatter.thumbnailImageAltText,
        highlighted: frontmatter.highlighted,
      };
    });
  };

  const getTopFeed = (limit = 4) => {
    const feedPages = getAllFeedFrontmatter().slice(0, limit);
    return [...feedPages];
  };

  return {
    getAllFeedFrontmatter,
    getTopFeed,
  };
};
