import {
  Card,
  Category,
  Excerpt,
  StyledImage,
} from "./feed-card-component-styles";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { useImage } from "@queries/use-image";

const FeedCard = (props) => {
  const {
    slug,
    summary,
    thumbnailImage,
    thumbnailImageAltText,
    category,
    ...rest
  } = props;
  const { getFluidImageName } = useImage();

  return (
    <Card {...rest}>
      <Link to={`/novidades/${slug}`}>
        <StyledImage
          fluid={getFluidImageName(thumbnailImage)}
          alt={thumbnailImageAltText}
        />
      </Link>
      <Category>{category}</Category>
      <Excerpt href={`/novidades/${slug}`}>{summary}</Excerpt>
    </Card>
  );
};

FeedCard.propTypes = {
  slug: PropTypes.string,
  category: PropTypes.string,
  summary: PropTypes.string,
  thumbnailImage: PropTypes.object,
  thumbnailImageAltText: PropTypes.string,
};

export default FeedCard;
