import { CardGrid } from "./feed-card-grid-component-styles";
import FeedCard from "./feed-card-component";
import PropTypes from "prop-types";
import React from "react";

const FeedCardGrid = (props) => {
  return (
    <CardGrid>
      {props.list.map((feed) => {
        const {
          slug,
          category,
          summary,
          thumbnailImage,
          thumbnailImageAltText,
        } = feed;
        return (
          <FeedCard
            key={String(summary).split(" ").join("")}
            slug={slug}
            category={category}
            summary={summary}
            thumbnailImage={thumbnailImage}
            thumbnailImageAltText={thumbnailImageAltText}
          />
        );
      })}
    </CardGrid>
  );
};

FeedCardGrid.propTypes = {
  list: PropTypes.arrayOf({
    slug: PropTypes.string,
    category: PropTypes.string,
    summary: PropTypes.string,
    thumbnailImage: PropTypes.object,
    thumbnailImageAltText: PropTypes.string,
  }),
};

export default FeedCardGrid;
