import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.p`
  margin-bottom: 13px;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
  max-width: 730px;
`;

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
`;
